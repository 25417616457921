import {HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http'
import {inject, LOCALE_ID} from '@angular/core'
import {catchError, Observable, throwError} from 'rxjs'

import {DEFAULT_LANG} from '@constants/default-lang'
import {AuthService} from '@services/auth.service'
import {LinkService} from '@services/link.service'

export const requestInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const lang = inject(LOCALE_ID)
  const authService = inject(AuthService)
  const linkService = inject(LinkService)

  return next(
    req.clone({
      setHeaders: {
        'Accept-Language': lang ?? DEFAULT_LANG,
      },
    }),
  ).pipe(
    catchError(e => {
      if (e.status === 401) {
        authService.keycloak.logout({
          redirectUri: linkService.buildLink(['/'], {queryParams: {sessionExpired: true}}),
        })
      }

      return throwError(() => new Error(e))
    }),
  )
}
